import React from "react";

import { Link } from "../../plugins/gatsby-plugin-atollon";

import FacebookIcon from "../assets/images/ifacebook.svg";
import InstagramIcon from "../assets/images/iinstagram.svg";
import LinkedInIcon from "../assets/images/ilinkedin.svg";
import YoutubeIcon from "../assets/images/iyoutube.svg";
import Logo from "../assets/images/LogoWordmark.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <Link to="/">
          <img src={Logo} alt="logo" className="footer__container__logo" />
        </Link>
        <div className="footer__container__top">
          <div className="footer__container__top__address-contact">
            <div className="footer-address">
              <h2 className="footer-address__label">Address</h2>
              <div className="footer-address__address">
                30 Lochiel Avenue <br />
                Mount Martha VIC 3934 <br />
                Australia
              </div>
            </div>
            <div className="footer-contact">
              <h2 className="footer-contact__label">Call us</h2>
              <Link to={`tel:+61359741401`}>
                <p className="footer-contact__number">(03) 5974 1401</p>
              </Link>
            </div>
          </div>
          <div className="footer__container__top__links-desktop">
            <div className="footer-nav">
              <Link
                id="filter-links"
                data-label="Buy"
                to="/properties/?property_type=Buy&beds=&baths=&cars=&max_price=&min_price=&search="
              >
                <p>Buy</p>
              </Link>
              <Link
                id="filter-links"
                data-label="Sold"
                to="/properties/?property_type=Sold&beds=&baths=&cars=&max_price=&min_price=&search="
              >
                <p>Sold</p>
              </Link>
              <Link
                id="filter-links"
                data-label="For Lease"
                to="/properties/?property_type=Lease&beds=&baths=&cars=&max_price=&min_price=&search="
              >
                <p>For Lease</p>
              </Link>
              <Link id="filter-links" data-label="Appraisals" to="/appraisals/">
                <p>Appraisals</p>
              </Link>
              <Link
                id="filter-links"
                data-label="Agents"
                to="/about-us/?team=true"
              >
                <p>Agents</p>
              </Link>
            </div>
          </div>
          <div className="footer__container__top__email-socials">
            <div className="footer-email">
              <h2 className="footer-email__label">Email Us</h2>
              <Link to={`mailto:sales@wnre.com.au`}>
                <p className="footer-email__mail">sales@wnre.com.au</p>
              </Link>
              <Link to={`mailto:rentals@wnre.com.au`}>
                <p className="footer-email__mail">rentals@wnre.com.au</p>
              </Link>
            </div>
            <div className="footer-socials">
              <h2 className="footer-socials__label">Socials</h2>
              <span>
                <Link to="https://www.facebook.com/warlimontandnutt/">
                  <img src={FacebookIcon} alt="facebook" />
                </Link>
                <Link to="https://www.instagram.com/warlimontandnutt/?hl=en">
                  <img src={InstagramIcon} alt="instagram" />
                </Link>
                <Link to="https://www.linkedin.com/company/warlimont-nutt-real-estate/">
                  <img src={LinkedInIcon} alt="linkedin" />
                </Link>
                <Link to="https://www.youtube.com/channel/UCFaTfYa5_blaX6AHJ9Ol0wA">
                  <img src={YoutubeIcon} alt="youtube" />
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="footer__container__line-one"></div>
        <div className="footer__container__mobile-links">
          <div className="footer-nav">
            <Link
              id="filter-links"
              data-label="Buy"
              to="/properties/?property_type=Buy&beds=&baths=&cars=&max_price=&min_price=&search="
            >
              <p>Buy</p>
            </Link>
            <Link
              id="filter-links"
              data-label="Sold"
              to="/properties/?property_type=Sold&beds=&baths=&cars=&max_price=&min_price=&search="
            >
              <p>Sold</p>
            </Link>
            <Link
              id="filter-links"
              data-label="For Lease"
              to="/properties/?property_type=Lease&beds=&baths=&cars=&max_price=&min_price=&search="
            >
              <p>For Lease</p>
            </Link>
            <Link id="filter-links" data-label="Appraisals" to="/appraisals/">
              <p>Appraisals</p>
            </Link>
            <Link
              id="filter-links"
              data-label="Agents"
              to="/about-us/?team=true"
            >
              <p>Agents</p>
            </Link>
          </div>
          <div className="footer-nav-second">
            <Link to="/about-us/">
              <p>About</p>
            </Link>
            <Link to="/news/">
              <p>News</p>
            </Link>
            <Link to="/about-us/?team=true">
              <p>Team</p>
            </Link>
          </div>
        </div>
        <div className="footer__container__line-two"></div>
        <div className="footer__container__bottom">
          <div className="footer__container__bottom__terms-privacy">
            <Link to="/terms-and-conditions/">
              <p>T&CS</p>
            </Link>
            <Link to="/privacy-policy/">
              <p>Privacy Policy</p>
            </Link>
          </div>
          <div className="footer__container__bottom__links-desktop-more">
            <div className="footer-nav-second">
              <Link to="/about-us/">
                <p>About</p>
              </Link>
              <Link to="/news/">
                <p>News</p>
              </Link>
              <Link to="/about-us/?team=true">
                <p>Team</p>
              </Link>
            </div>
          </div>
          <div className="footer__container__bottom__info">
            <p>© Warlimont & Nutt 2024</p>
            <p>
              Design by{" "}
              <Link
                to="https://atollon.com.au"
                target="_blank"
                rel="noreferrer"
              >
                Atollon
              </Link>
            </p>
          </div>
        </div>
        <div className="footer__container__about">
          <p>
            Warlimont & Nutt acknowledges that we are located on the lands and
            waters of the Boon Wurrung / Bunurung people, members of the Kulin
            Nation. We pay our respects to their Elders past, present and
            emerging.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
