import React from "react";

import { AtollonProvider } from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query";

import "../assets/scss/main.scss";

import Header from "../components/header";
import Footer from "../components/footer";

const Layout = (props) => {
  const globalData = layoutQuery();

  return (
    <AtollonProvider {...props} globalData={globalData}>
      <main
        className={
          props.location.pathname === "/about-us" ||
          props.location.pathname === "/about-us/" ||
          props.location.pathname === "/property-management" ||
          props.location.pathname === "/property-management/" ||
          props.location.pathname === "/careers" ||
          props.location.pathname === "/careers/"
            ? "dark-background"
            : ""
        }
      >
        <Header pathname={props.location.pathname} />
        {props.children}
        <Footer />
        <div id="bf-revz-widget-1249640211"></div>
      </main>
      <script 
        defer 
        type="text/javascript" 
        src="https://getbirdeye.com.au/embed/v6/163140/1/1249640211/f130faa599345674223de4f9673e2acadcc6d2102c17d1b3">
      </script>
    </AtollonProvider>
  );
};

export default Layout;
