import React, { useState, useEffect } from "react";

import { Link } from "../../plugins/gatsby-plugin-atollon";

import HamburgerIcon from "../assets/images/hamburger-icon.svg";
import FacebookIcon from "../assets/images/ifacebook.svg";
import InstagramIcon from "../assets/images/iinstagram.svg";
import LinkedInIcon from "../assets/images/ilinkedin.svg";
import YoutubeIcon from "../assets/images/iyoutube.svg";
import CrossIcon from "../assets/images/Plus.svg";
import SearchIcon from "../assets/images/search-icon.svg";

import LogoImage from "../assets/images/LogoWN.svg";
import LogoImageDark from "../assets/images/LogoWNDark.svg";

const Header = ({ pathname }) => {
  const [isVisible, setIsVisible] = useState(false);

  const isLogoScrollable = pathname === "/";

  const isLight = [
    "/contact-us",
    "/appraisals",
    "/team",
    "/terms-and-conditions",
    "/privacy-policy",
    "/thank-you",
  ].some((link) => pathname.includes(link));

  const closeMenu = () => {
    setIsVisible(false);
    const body = document.querySelector("body");
    body.classList.remove("scroll-prevent");
  };

  const toggleIsVisible = () => {
    setIsVisible(!isVisible);
    const body = document.querySelector("body");
    body.classList.toggle("scroll-prevent");
  };

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    const scrollTop = window.scrollY;

    if (scrollTop / 2 > 55) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  return (
    <header
      className={hasScrolled ? "has-scrolled" : ""}
      style={{ zIndex: isVisible ? 1002 : 1000 }}
    >
      <div
        className={
          isVisible ? " header__container header__dark" : "header__container"
        }
      >
        <Link
          to="/properties"
          className={`header__container__search ${
            isLight && !hasScrolled && !isVisible ? "is-light" : ""
          }`}
          onClick={() => closeMenu()}
        >
          <img src={SearchIcon} alt="hamburger" />
        </Link>

        {!isLogoScrollable && (
          <Link
            to="/"
            title="Warlimont & Nutt"
            className={`header__container__logo ${
              isLight && !hasScrolled && !isVisible ? "is-light" : ""
            }`}
            onClick={() => closeMenu()}
          >
            {isLight && !hasScrolled && !isVisible ? (
              <img src={LogoImageDark} alt="Warlimont & Nutt Logo" />
            ) : (
              <img src={LogoImage} alt="Warlimont & Nutt Logo" />
            )}
          </Link>
        )}

        <button
          className={`header__container__hamburger 
            ${isVisible ? "header__container__cross" : ""} 
            ${isLight && !hasScrolled && !isVisible ? "is-light" : ""}
          `}
          onClick={toggleIsVisible}
        >
          <img src={isVisible ? CrossIcon : HamburgerIcon} alt="hamburger" />
        </button>
      </div>
      <div className={isVisible ? "header__flyout" : "d-none"}>
        <div className="header__flyout__top">
          <div className="header__flyout__top__left">
            <div className="header-nav">
              <Link
                onClick={() => toggleIsVisible()}
                to="/properties?property_type=Buy&beds=&baths=&cars=&max_price=&min_price=&search="
                id="filter-links"
                data-label="Buy"
              >
                <p>Buy</p>
              </Link>
              <Link
                onClick={() => toggleIsVisible()}
                to="/properties?property_type=Sold&beds=&baths=&cars=&max_price=&min_price=&search="
                id="filter-links"
                data-label="Sold"
              >
                <p>Sold</p>
              </Link>
              <Link
                onClick={() => toggleIsVisible()}
                to="/properties?property_type=Lease&beds=&baths=&cars=&max_price=&min_price=&search="
                id="filter-links"
                data-label="For Lease"
              >
                <p>For Lease</p>
              </Link>
              <Link
                onClick={() => toggleIsVisible()}
                to="/property-management/"
                id="filter-links"
                data-label="Property Management "
              >
                <p>Property Management </p>
              </Link>
              <Link
                onClick={() => toggleIsVisible()}
                to="/appraisals/"
                id="filter-links"
                data-label="Selling"
              >
                <p>Selling</p>
              </Link>
            </div>
            <div className="header-nav-second">
              <Link
                onClick={() => toggleIsVisible()}
                to="/news/"
                id="filter-links"
                data-label="News"
              >
                <p>News</p>
              </Link>
              <Link
                onClick={() => toggleIsVisible()}
                to="/about-us?team=true"
                id="filter-links"
                data-label="Our Team"
              >
                <p>Our Team</p>
              </Link>
              <Link
                onClick={() => toggleIsVisible()}
                to="/careers/"
                id="filter-links"
                data-label="Careers"
              >
                <p>Careers</p>
              </Link>
              <Link
                onClick={() => toggleIsVisible()}
                to="/contact-us/"
                id="filter-links"
                data-label="Contact Us"
              >
                <p>Contact Us</p>
              </Link>
            </div>
          </div>
          <div className="header__flyout__top__right">
            <div className="header__flyout__top__right__address-contact">
              <div className="header-address">
                <h2 className="header-address__label">Address</h2>
                <div className="header-address__address">
                  30 Lochiel Avenue <br />
                  Mount Martha VIC 3934 <br />
                  Australia
                </div>
              </div>
              <div className="header-contact">
                <h2 className="header-contact__label">Call us</h2>
                <Link to={`tel:+61359741401`}>
                  <p className="header-contact__number">(03) 5974 1401</p>
                </Link>
              </div>
            </div>
            <div className="header__flyout__top__right__email-socials">
              <div className="header-email">
                <h2 className="header-email__label">Email Us</h2>
                <Link to={`mailto:sales@wnre.com.au`} onClick={toggleIsVisible}>
                  <p className="header-email__mail">sales@wnre.com.au</p>
                </Link>
                <Link
                  to={`mailto:rentals@wnre.com.au`}
                  onClick={toggleIsVisible}
                >
                  <p className="header-email__mail">rentals@wnre.com.au</p>
                </Link>
              </div>
              <div className="header-socials">
                <h2 className="header-socials__label">Socials</h2>
                <span>
                  <Link
                    to="https://www.facebook.com/warlimontandnutt/"
                    onClick={toggleIsVisible}
                  >
                    <img src={FacebookIcon} alt="facebook" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/warlimontandnutt/?hl=en"
                    onClick={toggleIsVisible}
                  >
                    <img src={InstagramIcon} alt="instagram" />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/warlimont-nutt-real-estate/"
                    onClick={toggleIsVisible}
                  >
                    <img src={LinkedInIcon} alt="linkedin" />
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UCFaTfYa5_blaX6AHJ9Ol0wA"
                    onClick={toggleIsVisible}
                  >
                    <img src={YoutubeIcon} alt="youtube" />
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="header__flyout__line"></div>
        <div className="header__flyout__bottom">
          <p>
            30 Lochiel Avenue <br />
            Mount Martha VIC 3934 <br />
            Australia
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
